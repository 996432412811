<!--
 * @Description: 消费项
 * @Author: 琢磨先生
 * @Date: 2022-05-19 00:25:45
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-09 11:20:31
-->


<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus">新增</el-button>
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData" border>
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column label="名称" prop="name" width="180"></el-table-column>
      <el-table-column label="自定义金额" prop="is_defined" width="100">
        <template #default="scope">{{ scope.row.is_defined ? "是" : "" }}</template>
      </el-table-column>
      <el-table-column label="单价"  width="140">
        <template #default="scope">
          <span v-if="!scope.row.is_defined">￥{{scope.row.decimal_unit_price}}{{scope.row.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="描述" prop="description" min-width="240"></el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-consumption :item="current" @success="loadData" ></edit-consumption>
</template>
<script>
import EditConsumption from "./edit_consumption.vue";

export default {
  components: {
    EditConsumption,
  },
  data() {
    return {
      types: [],
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.post("seller/v1/consumption").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/consumption/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>