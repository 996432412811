<!--
 * @Description: 新增、修改消费项
 * @Author: 琢磨先生
 * @Date: 2022-05-19 00:46:37
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-09 11:18:06
-->
<template>
  <el-dialog
    v-model="drawer"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false" 
    draggable
    :title="title"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="自定义金额">
        <el-switch v-model="form.is_defined"></el-switch>
        <div class="help-block">
          <el-icon><WarningFilled /></el-icon>添加消费项时可输入单价
        </div>
      </el-form-item>

      <el-form-item
        label="固定单价(元)"
        prop="decimal_unit_price"
        v-if="!form.is_defined"
      >
        <el-col :span="10">
          <el-input v-model="form.decimal_unit_price" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="单位" prop="unit" v-if="!form.is_defined">
        <el-col :span="8">
          <el-input v-model="form.unit" placeholder=""></el-input>
        </el-col>
        <div class="help-block">例如：元/瓶</div>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>

      <el-form-item label="描述" prop="description">
        <el-col :span="20">
          <el-input
            v-model="form.description"
            type="textarea"
            placeholder="描述内容"
          ></el-input
        ></el-col>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button
        type="primary"
        @click="onSubmit"
        :disabled="loading"
        :loading="loading"
        >确定</el-button
      >
      <!-- <el-button @click="cancelClick" :disabled="loading">取消</el-button> -->
    </template>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      title: "新增",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        item_id: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload"],
  emits: ["success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
          if (this.item.id) {
            this.title = "修改";
          } else {
            this.title = "新增";
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/seller/v1/consumption/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    // /**
    //  * 取消
    //  */
    // cancelClick() {
    //   this.drawer = false;
    //   if (this.reload) {
    //     this.reload();
    //   }
    // },
    // /**
    //  * 规格选择变动
    //  */
    // sizeChange(val) {
    //   console.log(val);
    // },
  },
};
</script>